import { SET_DOCUMENT } from './-mutation-types';
import { useDocumentsStore } from '~/stores/documents';
import type { Document } from '~/models/Document';

const Mutations = {
    [SET_DOCUMENT](payload: Document | null) {
        const documentsStore = useDocumentsStore();
        documentsStore.document = payload;
    },
};

export default Mutations;
